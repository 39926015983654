<template>
  <div>
    <vb-error-500 />
  </div>
</template>
<script>
import VbError500 from '@/@vb/components/Errors/500'
export default {
  components: {
    VbError500,
  },
}
</script>
